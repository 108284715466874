<template>
  <div>
    <v-menu offset-y z-index="300">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on" >
          Opções
        </v-btn>
      </template>
      <v-list dense>
        <v-list-tile v-prms="{'id': '2c491b4c', mode: 'disabled'}" @click="$WiEditDialog({wiConfig: 'chamados-todos', onDevice: config.refresh, data: {devices: makeSendDevicesToTicket(null, selected).devices, desc_chamado: makeTicketDescription(selected)}})" avatar ripple>
          <v-list-tile-avatar>
            <v-icon color="green">add</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Criar novo Chamado</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile v-prms="{'id': '1a825af1', mode: 'disabled'}" @click="dialogListChamados = true" avatar ripple>
          <v-list-tile-avatar>
            <v-icon color="orange">library_add</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Vincular a um Chamado existente</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <div v-if="dialogListChamados">
      <v-dialog v-model="dialogListChamados" scrollable max-width="95%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-toolbar dense><strong>Selecione um chamado</strong><v-spacer></v-spacer><v-btn icon @click="dialogListChamados = false"><v-icon>close</v-icon></v-btn></v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="padding: 0;">
            <chamados mode="component" pageComponent="todos" v-on:action="selectChamado"></chamados>
          </v-card-text>
          <v-divider></v-divider>
          <v-toolbar dense>
            <strong style="padding-right: 5px;"> {{selected.length}} </strong> indisponibilidades selecionadas
          </v-toolbar>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import Chamados from '@/modules/service-desk/pages/Chamados/Chamados'
  export default {
    name: 'NetworkDevicesSelectedAction',
    data () {
      return {
        dialogListChamados: false
      }
    },
    methods: {
      selectChamado: function (chamado) {
        this.$swal({
          title: 'Deseja realmente vincular ao chamado #' + chamado.id + '?',
          text: 'Esta ação só poderá ser desfeita manualmente!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Vincular!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            callApi.post({
              uri: 'service-desk/ticket/device',
              data: this.makeSendDevicesToTicket(chamado.id, this.selected),
              msgLoad: {title: 'Vinculando, por favor aguarde.'},
              sucess: () => {
                this.dialogListChamados = false
              },
              msgSucess: {title: 'Indisponibilidades Vinculadas!'},
              error: (err) => {
                console.log(err)
              },
              msgError: true
            })
          }
        })
      },
      makeSendDevicesToTicket: function (ticketId, devices) {
        var dataToSend = {ticket_id: ticketId, devices: []}
        devices.filter((device) => {
          dataToSend.devices.push(device.id)
        })
        return dataToSend
      },
      makeTicketDescription: function (selected) {
        var text = ''
        selected.filter((item, key) => {
          if (key) {
            text += ', \n'
          }
          text += item.nome_disp
          // text += ' em ' + item.grupos[0].nome_grupo + ' / ' + item.grupos[1].nome_grupo
          text += item.status_indi > 0 ? ' está (Disponível)' : ' está (Indisponível)'
        })
        return text
      }
    },
    props: {
      selected: Array,
      config: Object
    },
    components: {
      Chamados
    }
  }
</script>
<style scoped>
  .permission_disabled >>> * {
    color: rgba(0,0,0,.26) !important;
  }
</style>
